import bgfreight from './bgfreight.webp?url'
import bnsf from './BNSF.webp?url'
import cma from './cma.webp?url'
import contargo from './contargo.webp?url'
import cosco from './cosco.webp?url'
import cpkc from './cpkc.webp?url'
import dfds from './dfds.webp?url'
import dpworld from './dpworld.webp?url'
import ellerman from './ellerman.webp?url'
import emirates from './emirates.webp?url'
import evergreen from './evergreen.webp?url'
import greencargo from './greencargo.webp?url'
import hapag from './hapag.webp?url'
import hmm from './hmm.webp?url'
import hupac from './hupac.webp?url'
import kombiverkehr from './kombiverkehr.webp?url'
import maersk from './Maersk.webp?url'
import msc from './msc.webp?url'
import naviland from './naviland.webp?url'
import one from './one.webp?url'
import pil from './PIL.webp?url'
import railcargocskd from './railcargo-cskd.webp?url'
import samskip from './samskip.webp?url'
import sealead from './SeaLead.webp?url'
import sitc from './SITC.webp?url'
import unifeeder from './unifeeder.webp?url'
import unionpacific from './unionpacific.webp?url'
import wanhai from './wan_hai.webp?url'
import xpressfeeders from './x-press feeders.webp?url'

export default [
  bgfreight,
  bnsf,
  cma,
  contargo,
  cosco,
  cpkc,
  dfds,
  dpworld,
  ellerman,
  emirates,
  evergreen,
  greencargo,
  hapag,
  hmm,
  hupac,
  kombiverkehr,
  maersk,
  msc,
  naviland,
  one,
  pil,
  railcargocskd,
  samskip,
  sealead,
  sitc,
  unifeeder,
  unionpacific,
  wanhai,
  xpressfeeders
]
